<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    persistent
    width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        dense
        depressed
        outlined
        :color="selectedMultipleFilters.length>0?'primary':''"
        style="min-width: 67px; max-width: 300px"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small :color="selectedMultipleFilters.length>0?'primary':''">
          {{ icons.mdiFilter }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Filter for "{{ label || '' }}"</v-card-title>
      <v-card-text>
        <v-row>
          <template v-for="(filter,i) in items">
            <v-col
              :key="i"
              cols="12"
            >
              <v-text-field
                v-if="filter.type === 'text'"
                v-model="filter.value"
                dense
                outlined
                :placeholder="filter.place_holder || ''"
                hide-details
                full-width
                style="min-width:30px;"
                class="filter-input"
              />
              <template v-if="filter && filter.type === 'select'">
                <template v-if="filter.label !== 'color_name'">
                  <i-autocomplete
                    v-if="filter.source"
                    v-model="filter.value"
                    :items="filter.values || []"
                    :item-text="filter.label"
                    :item-value="filter.id"
                    :label="filter.text || ''"
                    :placeholder="filter.place_holder || ''"
                    :no-data-text="$t('No data')"
                    :no-results-text="$t('No result')"
                    full-width
                    outlined
                    dense
                    hide-details
                    :multiple="filter.multiple"
                    :source="filter.source"
                    clearable
                    chips
                    deletable-chips
                    style="min-width: 60px;"
                  ></i-autocomplete>
                  <v-autocomplete
                    v-else
                    v-model="filter.value"
                    :items="filter.values || []"
                    :item-text="filter.label"
                    :item-value="filter.id"
                    :label="filter.text || ''"
                    :placeholder="filter.place_holder || ''"
                    :no-data-text="$t('No data')"
                    :no-results-text="$t('No result')"
                    full-width
                    outlined
                    dense
                    hide-details
                    :multiple="filter.multiple"
                    clearable
                    chips
                    deletable-chips
                    style="min-width: 60px;"
                  ></v-autocomplete>
                </template>
                <template v-else>
                  <v-autocomplete
                    v-if="filter.is_visual_idea"
                    v-model="filter.value"
                    :label="filter.text"
                    :items="$store.state['app'].colors.filter((el) => $store.state.app.settings.visual_idea_colors_ids.includes(el.id) )"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    clearable
                    deletable-chips
                    :multiple="true"
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    v-else
                    v-model="filter.value"
                    :label="filter.text"
                    :items="$store.state['app'].colors.filter((el) => !$store.state.app.settings.visual_idea_colors_ids.includes(el.id) )"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    clearable
                    deletable-chips
                    :multiple="true"
                  >
                    <template #item="{ item }">
                      <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.color_name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                    <template #selection="{ item }">
                      <v-item
                        :style="{'background-color': item.bg_color, 'color': item.txt_color, 'width': '100%' }"
                        style="padding: 0px 4px; width: 100%"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.color_name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                  </v-autocomplete>
                </template>
              </template>
              <template v-if="filter.type === 'date'">
                <date-picker
                  v-if="!filter.range"
                  v-model="filter.value"
                  :label="filter.text || ''"
                  first-day-of-week="1"
                  no-title
                  scrollable
                  :range="false"
                  :type="filter.month?'month':'date'"
                  style="min-width: 67px"
                />
                <date-range-picker
                  v-else
                  v-model="filter.value"
                  :label="filter.text || ''"
                  :max-date="filter.noLimit?null:today"
                  first-day-of-week="1"
                  no-title
                  scrollable
                  :type="filter.month?'month':'date'"
                  style="min-width: 67px"
                />
              </template>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="modal = false"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          depressed
          color="primary"
          @click="save"
        >
          Validate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiFilter,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import DatePicker from '@/components/DatePicker'
import DateRangePicker from '@/components/DateRangePicker'
import IAutocomplete from "@/components/IAutocomplete";

export default {
  components: {IAutocomplete, DateRangePicker, DatePicker },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Filtre',
    },
  },
  setup(props, { emit }) {
    const modal = ref(false)
    const today = ref((new Date(Date.now())).toISOString().substr(0, 10))
    const items = computed({
      get: () => props.filters,
      set: value => emit('update:object', value),
    }, { deep: true })

    const selectedMultipleFilters = computed({
      get: () => {
        const selected = []
        selected.push(...items.value.filter(ele => ele.value !== '' && ele.value != null && (ele.value.constructor !== Array || ele.value.length > 0))
          .map(ele => ({ ...ele, values: null/* , type: null */ })))

        return selected
      },
    }, { deep: true })

    const save = () => {
      modal.value = false
      emit('update:object', items.value)
      console.log('saved click')
    }

    return {
      items,
      today,
      save,
      modal,
      selectedMultipleFilters,
      icons: {
        mdiFilter,
      },
    }
  },
}
</script>
