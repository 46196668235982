<template>
  <v-autocomplete
    v-model="item"
    v-bind="$attrs"
    :items="items"
    :search-input.sync="search"
    :loading="loading"
  >
    <!--    <template #item="{ item }">
      <slot name="item" :item="item"></slot>
    </template>
    <template #selection="{ item }">
      <slot name="selection"  :item="item"></slot>
    </template>-->
    <template #no-data>
      <slot name="no-data"></slot>
    </template>
  </v-autocomplete>
</template>

<script>

import { computed, ref, watch } from '@vue/composition-api'

import axios from '@axios'

export default {
  props: {
    value: {
      type: [Number, Object, Array],
      default: null,
    },
    source: {
      type: String,
      default: '',
    },
    itemsField: {
      type: String,
      default: 'data',
    },
    object: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const items = ref([])
    const search = ref('')
    const loading = ref(false)
    const item = computed({
      get: () => props.value,
      set: value => emit('input', value),
    })

    if (props.object && item.value) {
      if (props.object.constructor === Array) {
        items.value.push(...props.object)
      } else {
        items.value.push(props.object)
      }
    }
    const getItemValue = (model, str, d = null) => {
      if (model == null || !str) {
        return d
      }
      try {
        if (str.indexOf('.') < 0) {
          if (model[str] === 0) return 0

          return model[str] || d
        }
        const current = str.slice(0, (str.indexOf('.')))
        const next = str.slice((str.indexOf('.')) + 1)
        model = model[current]

        return (model) ? this.getItemValue(model, next, d) : ''
      } catch (e) {
        return d
      }
    }
    const fetchItems = () => {
      if (props.source && props.source.length > 0) {
        axios.get(props.source, { params: { term: search.value } })
          .then(response => {
            const data = getItemValue(response.data, props.itemsField || 'data', [])
            for (let i = 0; i < data.length; i++) {
              const u = data[i]
              const indexU = items.value.findIndex(el => el.id === u.id)
              if (indexU < 0) {
                items.value.push(u)
              }
            }
          }).then(() => {
            loading.value = false
          })
      }
    }
    let timeout = null
    watch(search, () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        loading.value = true
        fetchItems()
      }, 500)
    }, { deep: true })

    return {
      item,
      loading,
      search,
      items,
    }
  },
}
</script>
